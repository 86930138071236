<template>
  <div v-shortkey="shortkeys" @shortkey="triggerShortkey">
    <h4>Clinical <u>Q</u>uestions</h4>
    <loader v-if="isLoading" size="small" class="mx-auto" />
    <div class="d-flex flex-wrap">
      <div class="col-6 mt-2" v-for="question in questions" :key="question.id">
        <template v-if="question.id">
          <TextInput
            ref="questionInputs"
            v-if="question.cytQuestionType === 'Text'"
            :label="question.displayText"
            v-model="answerMap[question.id]"
            :required="question.isResponseRequired"
            type="text"
          />
          <DatePicker
            v-else-if="question.cytQuestionType === 'Date'"
            :label="question.displayText"
            ref="questionInputs"
            v-model="answerMap[question.id]"
            :required="question.isResponseRequired"
          />
          <textarea
            v-else-if="question.cytQuestionType === 'textarea'"
            ref="questionInputs"
            v-model="answerMap[question.id]"
            :required="question.isResponseRequired"
          ></textarea>
          <SelectInput
            v-else-if="question.cytQuestionType === 'List'"
            ref="questionInputs"
            :label="question.displayText"
            :items="question.options"
            v-model="answerMap[question.id]"
            :required="question.isResponseRequired"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { dateRangeFilter, getAltKeys } from "@/modules/helpers";
import cytologyService, { CytologyEntities } from "@/services/cytology";
import { mapState } from "vuex";
import DatePicker from "../common/DatePicker.vue";
import TextInput from "../common/TextInput.vue";
import SelectInput from "../common/SelectInput.vue";
import Loader from "../common/Loader.vue";

export default {
  components: {
    DatePicker,
    TextInput,
    SelectInput,
    Loader
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    caseId: {
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      questionResponses: [],
      molecTests: [],
      cytologyQuestionsStore: cytologyService.createSearch(CytologyEntities.CytQuestions),
      labQuestions: {},
      shortkeys: getAltKeys("q")
    };
  },
  created() {
    this.loadLabQuestions();
  },
  methods: {
    triggerShortkey(event) {
      switch (event.srcKey) {
        case "q":
          this.focusFirstInput();
          break;
      }
    },
    focusFirstInput() {
      this.$nextTick(() => {
        const refs = this.$refs.questionInputs;
        if (refs && refs.length) {
          refs[0].focus();
        }
      });
    },
    async loadLabQuestions() {
      try {
        this.isLoading = true;
        const labQuestions = await this.cytologyQuestionsStore.load({
          filter: dateRangeFilter(),
          sort: [
            {
              selector: "seqNum",
              desc: false
            }
          ]
        });

        if (!labQuestions?.length) return;
        for (const question of labQuestions) {
          if (question.cytQuestionType === "List") {
            const questionDetails = await cytologyService.getSingleEntity(
              CytologyEntities.CytQuestions,
              question.id
            );
            question.options = questionDetails.options;
          }
        }
        this.labQuestions = labQuestions.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        await this.focusFirstInput();
        //! TODO: add error handling
      } finally {
        this.isLoading = false;
      }
    },
    setResponse(questionId, response) {
      this.answerMap[questionId] = response;
    },
    getQuestionResponse(questionId) {
      const response = this.questionResponses.find(q => q.questionId === questionId);
      return response ? response.response : "";
    }
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.caseDetails.specimens
    }),
    answerMap: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    existingResponses() {
      return this.questionResponses.reduce((acc, curr) => {
        acc[curr.cytQuestionId] = curr.id;
        return acc;
      }, {});
    },
    questions() {
      return Object.values(this.labQuestions);
    }
  }
};
</script>

<style lang="scss" scoped>
.questions-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  .loader {
    align-self: center;
  }

  .text-input {
    min-height: fit-content;
  }
}
</style>
