<template>
  <form
    class="px-4 pt-4"
    v-shortkey="shortkeys"
    @shortkey="triggerShortkey"
    @submit.prevent="handleSubmit"
  >
    <div class="row">
      <div class="col-6">
        <CaseCytologyQuestions v-model="caseQuestions" :caseId="caseDetails.caseId" />
      </div>
      <div class="col-6">
        <text-input
          label="Clinical ICD10"
          maxLength="81"
          id="clinicalICDCode"
          :validator="$v.specimen.clinicalICDCode"
          v-model="specimen.clinicalICDCode"
          :forceUpperCase="upperCaseClinicalIcd10"
        />
        <div id="editors" class="mt-4" ref="editors" :class="editorClasses">
          <h4 class="mb-3">Clinical</h4>
          <MacroEnabledEditor
            v-for="editor in availableEditors"
            :id="editor.name"
            :key="editor.name"
            :expanded="true"
            :ref="editor.name"
            v-bind="editor"
            v-model="specimen[editor.name]"
            @editorReady="handleAutoOpenEditor"
          />
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end mt-3">
      <div>
        <loader v-if="isLoading" size="small" />
        <button
          v-if="permissions.CaseSpecimenDelete"
          :disabled="formDisabled"
          @click="deleteSpecimen(specimen)"
          type="button"
          class="btn btn-danger"
        >
          Delete
        </button>
        <button type="submit" class="btn btn-primary ml-2" :disabled="formDisabled" id="saveBtn">
          Save
        </button>
      </div>
    </div>
    <modal :status="isMacroDialogOpen" @close="isMacroDialogOpen = false">
      <macro-popup
        @close="isMacroDialogOpen = false"
        :targetType="macroDialogType"
        :targetSpecimen="specimen"
        @macroSelected="macroDialogCallback"
        :dialogFromWysiwyg="macroFromWysiwyg"
      />
    </modal>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UsersAPI from "@/services/users";
import { maxLength } from "vuelidate/lib/validators";
import MacroEnabledEditor from "@/components/common/MacroEnabledEditor";
import eventBus, {
  DISPATCH_NUMBER_OF_BLOCKS,
  fromBusEvent,
  OPEN_MACRO_POPUP,
  REQUEST_NUMBER_OF_BLOCKS,
  RESTORE_EDITOR_POSITION,
  SAVE_FROM_CASE_HEADER
} from "../../modules/eventBus";
import { filter, switchMap, tap } from "rxjs/operators";
import { CasesApi, CassettesApi, DropdownApi, PrintersApi, ReportsApi } from "../../services";
import TextInput from "@/components/common/TextInput.vue";
import { cloneDeep } from "lodash";
import Modal from "@/components/common/Modal.vue";
import MacroPopup from "@/components/MacroPopup.vue";
import Loader from "@/components/common/Loader.vue";
import { mergeSpecimensWithMacros } from "@/modules/mergeSpecimensWithMacros";
import physicianPopup from "@/mixins/physicianPopup.js";
import DataSource from "devextreme/data/data_source";
import { sortBy } from "lodash";
import { initialEditors } from "@/store/ApplicationSettings";
import {
  CaseEditTypeEnum,
  CaseStatusEnum,
  CytQuestionTypeEnum,
  SpecimenFocusEnum
} from "@/modules/enums";
import { getAltKeys, dateRangeFilter, removeExtraDivs } from "@/modules/helpers";
import { handleErrors } from "@/modules/handleErrors";
import { printFileDrop } from "@/modules/printFileDrop";
import CaseCytologyQuestions from "@/components/forms/CaseCytologyQuestions.vue";
import cytologyService, { CytologyEntities } from "@/services/cytology";

export default {
  name: "ClinicalData",
  components: {
    MacroEnabledEditor,
    TextInput,
    Modal,
    MacroPopup,
    Loader,
    CaseCytologyQuestions
  },
  mixins: [physicianPopup],
  props: {
    value: {
      type: Object
    },
    bodyParts: Array,
    protocolItems: Array,
    isUnlocked: Boolean
  },
  validations() {
    return {
      specimen: {
        clinicalICDCode: {
          maxLength: maxLength(80)
        }
      }
    };
  },
  data: () => ({
    caseQuestions: {},
    userSearch: UsersAPI.searchStore,
    grosser: null,
    cutter: null,
    isMacroDialogOpen: false,
    isLoading: false,
    macroDialogType: 0,
    labQuestions: {},
    macroFromWysiwyg: false,
    specimen: {},
    cytologyQuestionsStore: cytologyService.createSearch(CytologyEntities.CytQuestions),
    questionResponses: [],
    isModalOpen: false,
    shortkeys: getAltKeys("cegimnoprs"),
    staticCaseId: null
  }),
  created() {
    this.loadLabQuestions();
  },
  mounted() {
    eventBus.$on(SAVE_FROM_CASE_HEADER, () => this.saveSpecimen(this.specimen));
    if (this.specimens?.length) {
      this.specimen = cloneDeep(this.specimens[0]);
    }
  },
  beforeDestroy() {
    this.autoPrintOrders();
    eventBus.$off(SAVE_FROM_CASE_HEADER);
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      caseDetails: state => state.accessionStore.caseDetails,
      ForceUpperCaseGross: state => state.labSettings.ForceUpperCaseGross,
      ForceUpperCaseSite: state => state.labSettings.ForceUpperCaseSite,
      ForceUpperCaseClinical: state => state.labSettings.ForceUpperCaseClinical,
      ForceUpperCaseCaseNote: state => state.labSettings.ForceUpperCaseCaseNote,
      ForceUpperCaseClinicalICD10: state => state.labSettings.ForceUpperCaseClinicalICD10,
      AdditionalFields: state => state.labSettings.AdditionalFields,
      specimens: state => state.accessionStore.specimens,
      caseEditType: state => state.accessionStore.editType,
      textEditors: state => state.applicationSettings.textEditors || initialEditors,
      casePrefix: state => state.accessionStore.casePrefix,
      autoOpenEditors: state => state.applicationSettings.autoOpenEditors,
      specimenFocus: state => state.labSettings.SpecimenFocus,
      getSiteFromProtocolDescription: state => state.labSettings.GetSiteFromProtocolDescription,
      SpecimenNonDiagnosticChange: state => state.labSettings.SpecimenNonDiagnosticChange,
      currentLab: state => state.currentLab,
      DefaultQtyPiecesPerCassette: state => state.labSettings.DefaultQtyPiecesPerCassette,
      PrintSlidesOnSpecimenSave: state => state.labSettings.PrintSlidesOnSpecimenSave,
      defaultGlassSlidePrinter: state => state.applicationSettings.defaultGlassSlidePrinter,
      FileDropPrintingConfiguration: state => state.labSettings.FileDropPrintingConfiguration,
      defaultCassettePrinter: state => state.applicationSettings.defaultCassettePrinter,
      LabSettingDefaultCassettePrinter: state => state.labSettings.DefaultCassetteProcedurePrinter,
      LabBinPrintOrder: state => state.labSettings.LabBinPrintOrder,
      labSettings: state => state.labSettings,
      shouldPrintOrders: state => state.accessionStore.shouldPrintOrders
    }),
    ...mapGetters("accessionStore", ["isCaseEditable", "isReported"]),
    ...mapGetters(["permissions"]),
    editorClasses() {
      return {
        upperCaseClinical: parseInt(this.ForceUpperCaseClinical) || false,
        upperCaseCaseNote: parseInt(this.ForceUpperCaseCaseNote) || false,
        upperCaseGross: parseInt(this.ForceUpperCaseGross) || false
      };
    },
    caseId() {
      return this.caseDetails.caseId;
    },
    availableEditors() {
      const { CaseFieldEditClinical } = this.permissions;

      return sortBy(
        [
          // CaseFieldEditGross && { name: "gross", ...this.textEditors.gross },
          // CaseFieldEditCaseNotes && {
          //   name: "caseNotes",
          //   ...this.textEditors.caseNotes,
          //   accessKey: "n"
          // },
          CaseFieldEditClinical && { name: "clinical", ...this.textEditors.clinical }
        ],
        "specimenOrder"
      ).filter(value => value);
    },

    bodyPartsSorted() {
      return new DataSource({
        store: this.bodyParts,
        sort: ["displayName"]
      });
    },

    upperCaseGross() {
      if (Number(this.ForceUpperCaseGross)) {
        return true;
      }
      return false;
    },
    upperCaseCaseNote() {
      if (Number(this.ForceUpperCaseCaseNote)) {
        return true;
      }
      return false;
    },
    upperCaseSite() {
      if (Number(this.ForceUpperCaseSite)) {
        return true;
      }
      return false;
    },
    upperCaseClinical() {
      if (Number(this.ForceUpperCaseClinical)) {
        return true;
      }
      return false;
    },
    status() {
      return this.caseDetails.status;
    },
    formDisabled() {
      if (this.caseDetails.status === CaseStatusEnum.ReportedPrelim) {
        return !this.permissions.CaseSpecimenCreateEdit;
      }
      if (this.isReported) {
        if (this.isCaseEditable) {
          return (
            !this.SpecimenNonDiagnosticChange &&
            [CaseEditTypeEnum.NonDiagnostic, CaseEditTypeEnum.Billing].includes(this.caseEditType)
          );
        }
        return true;
      } else if (this.isCaseEditable) {
        return !this.permissions.CaseSpecimenCreateEdit;
      } else {
        return !this.isCaseEditable;
      }
    },
    draggableEditors: {
      get() {
        return this.availableEditors;
      },
      set(value) {
        const newEditors = value
          .map((editor, idx) => {
            return {
              ...editor,
              specimenOrder: idx
            };
          })
          .reduce((acc, curr) => {
            acc[curr.name] = curr;
            return acc;
          }, {});
        this.$store.commit("applicationSettings/setEditorsOrder", {
          ...this.textEditors,
          ...newEditors
        });
      }
    },
    isFrozenEnabled() {
      return this.permissions.CaseFieldEditFrozen;
    },
    isProstateProtocol() {
      if (this.specimen.protocolId) {
        const protocol = this.protocolItems.find(e => e.id === this.specimen.protocolId);
        if (protocol) {
          return protocol?.isProstate;
        }
      }
      return false;
    },
    upperCaseClinicalIcd10() {
      return this.ForceUpperCaseClinicalICD10;
    }
  },
  watch: {
    specimen: {
      deep: true,
      handler(nv) {
        this.$store.commit("accessionStore/setCurrentSpecimen", nv);
      }
    },
    grosser(nv) {
      if (nv.id != this.specimen.grosserUserId) {
        this.specimen.grosserUserId = nv.id;
      }
    },
    cutter(nv) {
      if (nv.id != this.specimen.cutterUserId) {
        this.specimen.cutterUserId = nv.id;
      }
    },
    "specimen.cutterUserId": {
      immediate: true,
      handler: function (nv) {
        if (nv && nv != this.cutter?.id) {
          this.userSearch.load({ filter: ["id", "=", nv] }).then(res => {
            this.cutter = res[0];
          });
        }
      }
    },
    "specimen.grosserUserId": {
      immediate: true,
      handler: function (nv) {
        if (nv && nv != this.grosser?.id) {
          this.userSearch.load({ filter: ["id", "=", nv] }).then(res => {
            this.grosser = res[0];
          });
        }
      }
    },
    "specimen.specimenOrder": {
      immediate: true,
      handler: function (nv) {
        if (nv && /[a-z]/i.test(nv)) {
          this.specimen.specimenOrder = nv.toUpperCase();
        }
      }
    },
    "specimen.cassettes": {
      immediate: true,
      handler(nv) {
        if (nv?.length !== this.specimen.numberOfBlocks) {
          this.specimen.numberOfBlocks = nv?.length || 0;
        }
      }
    },
    "caseDetails.caseId": {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.staticCaseId = parseInt(nv);
        }
      }
    }
  },
  subscriptions() {
    const openMacroFromWysiwyg$ = fromBusEvent(OPEN_MACRO_POPUP).pipe(
      tap(({ type }) => {
        this.macroDialogType = type;
        this.isMacroDialogOpen = true;
        this.macroFromWysiwyg = true;
      }),
      switchMap(({ callback }) => {
        return fromBusEvent(RESTORE_EDITOR_POSITION).pipe(
          tap(macros => {
            this.macroFromWysiwyg = false;
            this.macroDialogType = 0;
            this.isMacroDialogOpen = false;
            callback(macros);
            for (const macro of macros) {
              ["diagnosis", "microscopic", "notes", "caseNotes", "gross", "clinical"].forEach(
                property => {
                  if (macro[property]) {
                    this.$refs[property].expand();
                    if (property === "diagnosis") {
                      this.$refs[property].focus();
                      this.$refs[property].$el.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "center"
                      });
                    }
                  }
                }
              );
            }
          })
        );
      })
    );
    //Connects the block num field to the
    const changeBlockNum$ = fromBusEvent(DISPATCH_NUMBER_OF_BLOCKS).pipe(
      filter(data => this.value.id === data.id),
      tap(data => {
        this.numberOfBlocks_old = data.numberOfBlocks ?? this.numberOfBlocks_old;
        this.$emit("input", {
          ...this.value,
          protocolId: this.protocolId,
          numberOfBlocks: data.numberOfBlocks || this.value.numberOfBlocks,
          cassettes: data?.cassettes?.length ? data.cassettes : this.value?.cassettes
        });
      })
    );
    const blockNumber$ = this.$watchAsObservable("specimen.numberOfBlocks", { deep: true }).pipe(
      filter(({ newValue }) => {
        if (this.specimen.id) {
          const savedSpecimen = this.specimens.find(spec => spec.id === this.specimen.id);
          return Number(newValue) !== Number(savedSpecimen.numberOfBlocks);
        }
        return false;
      }),
      tap(async ({ newValue, oldValue }) => {
        if (newValue === 0) {
          const confirm = await window.confirm(
            "Setting number of blocks to 0 will remove all orders. <br/> Are you sure?"
          );
          if (!confirm) {
            return;
          }
        } else if (newValue < this.value.numberOfBlocks) {
          const confirm = await window.confirm(
            "Decreasing the number of blocks will remove associated orders. <br/> Are you sure?"
          );
          if (!confirm) {
            this.specimen.numberOfBlocks = oldValue;
          }
        }
        if (newValue !== this.specimen.cassettes.length) {
          this.updateCassettes();
        }
      })
    );
    const requestBlockNum$ = fromBusEvent(REQUEST_NUMBER_OF_BLOCKS).pipe(
      tap(() => {
        const numberOfBlocks = this.numberOfBlocks;
        eventBus.$emit(DISPATCH_NUMBER_OF_BLOCKS, {
          id: this.value.id,
          numberOfBlocks,
          cassettes: this.value.cassettes
        });
      })
    );

    return {
      blockNumber$,
      changeBlockNum$,
      requestBlockNum$,
      openMacroFromWysiwyg$
    };
  },
  methods: {
    async loadLabQuestions() {
      try {
        this.isLoading = true;
        const labQuestions = await this.cytologyQuestionsStore.load({
          filter: dateRangeFilter(),
          sort: [
            {
              selector: "seqNum",
              desc: false
            }
          ]
        });

        if (!labQuestions?.length) return;
        for (const question of labQuestions) {
          if (question.cytQuestionType === "List") {
            const questionDetails = await cytologyService.getSingleEntity(
              CytologyEntities.CytQuestions,
              question.id
            );
            question.options = questionDetails.options;
          }
        }
        this.labQuestions = labQuestions.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        await this.loadResponses();
        //! TODO: add error handling
      } finally {
        this.isLoading = false;
      }
    },
    async loadResponses() {
      try {
        this.isLoading = true;
        this.questionResponses = await CasesApi.getCytologyResponses(this.caseId);
        if (this.questionResponses.length > 0) {
          this.caseQuestions = this.questionResponses.reduce((acc, curr) => {
            acc[curr.cytQuestionId] = curr.response;
            return acc;
          }, {});
        }
      } finally {
        this.isLoading = false;
      }
    },
    async handleSaveQuestions() {
      try {
        this.isLoading = true;
        const items = [];
        for (const questionId in this.caseQuestions) {
          const question = this.labQuestions[questionId];
          const response = this.questionResponses.find(
            e => e.cytQuestionId === parseInt(questionId)
          );
          if (!question) continue;
          const item = {
            cytQuestionId: parseInt(questionId),
            response: this.caseQuestions[questionId]
          };
          if (question.cytQuestionType === CytQuestionTypeEnum.List) {
            item.cytQuestionOptionId = this.caseQuestions[questionId];
          }
          if (response) {
            item.id = response?.id;
          }
          items.push(item);
        }
        const putRecords = items.filter(e => e.id);
        const postRecords = items.filter(e => !e.id);
        if (putRecords.length) {
          await CasesApi.updateCytologyResponses(this.caseDetails.caseId, {
            id: this.caseDetails.caseId,
            items: putRecords
          });
        }
        if (postRecords.length) {
          await CasesApi.insertCytologyResponses(this.caseDetails.caseId, {
            id: this.caseDetails.caseId,
            items: postRecords
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    triggerShortkey(event) {
      switch (event.srcKey) {
        case "i":
          this.$refs.site.focus();
          break;
        case "p":
          this.$refs.protocol.focus();
          break;
        case "g":
          this.$refs.gross[0].focus();
          break;
        case "c":
          this.$refs.clinical[0].focus();
          break;
        case "n":
          this.$refs.caseNotes[0].focus();
          break;
        case "m":
          this.$refs.measurement.focus();
          break;
        case "e":
          this.togglePiecesPopup();
          break;
        case "r":
          this.printBlocks();
          break;
        case "s":
          this.saveSpecimen(this.specimen);
          break;
        case "o":
          if (this.isFrozenEnabled) {
            this.toggleFrozenForm();
          }
          break;
        default:
          break;
      }
    },
    async useMacroOnSpecimen(macros, name) {
      try {
        this.$store.commit("accessionStore/setLoading", true);
        if (name) {
          const [updateSpecimen] = await mergeSpecimensWithMacros(
            [this.specimen],
            macros,
            macros.map(macro => `.${macro.macroName}`).join("")
          );
          for (const prop in updateSpecimen) {
            if (prop !== name) {
              this.specimen[prop] = updateSpecimen[prop];
            }
          }
        }
        for (const macro of macros) {
          if (macro.holdCodes?.length) {
            macro.holdCodes.forEach(holdCodeId => {
              if (Object.hasOwnProperty.call(holdCodeId, "id")) {
                holdCodeId = holdCodeId.id;
              }
              this.$store.dispatch("accessionStore/upsertCaseQuickLink", {
                type: "H",
                tagId: holdCodeId,
                caseId: this.specimen.caseId,
                text: `Added by Macro: ${macro.macroName} on Specimen: ${this.specimen.specimenOrder}`
              });
            });
          }
          if (macro.icdCodes?.length && !this.specimen?.isICDModified) {
            await this.$store
              .dispatch("accessionStore/runIcdEngine", {
                specimen: this.specimen,
                macro
              })
              .then(specimen => {
                this.specimen.icdCodes = specimen.icdCodes;
              });
          }
        }
      } catch (e) {
        console.error("Error loading macro from dialog", e);
        window.notify("Error occured loading macro.", "error");
      } finally {
        this.$store.commit("accessionStore/setLoading", false);
      }
    },
    async macroDialogCallback(macros) {
      this.isMacroDialogOpen = false;
      const updatedSpecimens = await mergeSpecimensWithMacros(
        [this.specimen],
        macros,
        macros.map(macro => `.${macro.macroName}`).join("")
      );
      this.specimen = updatedSpecimens[0];
      await this.useMacroOnSpecimen(macros);
      ["clinical", "gross", "caseNotes", "notes", "microscopic", "diagnosis"].forEach(property => {
        if (this.specimen[property]) {
          this.$refs[property].isExpanded = true;
        }
      });
    },
    async deleteSpecimen(specimen) {
      const confirm = await window.confirm(
        `<span class='text-danger font-weight-bold' style='font-size: 16pt;'>This action is irreversible!<br> Are you sure you want to delete specimen ${specimen.specimenOrder}?</span>`
      );
      if (!confirm) {
        return;
      }
      this.$emit("delete");
      this.$store.dispatch("accessionStore/removeCaseSpecimen", specimen);
    },
    async handleSubmit() {
      if (!this.specimens.length) {
        return;
      }
      const specimen = this.specimens[0];
      if (this.formDisabled) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }

      if (specimen.numberOfBlocks && !specimen.protocolId) {
        window.alert("A protocol must be selected before increasing number of blocks.");
        return;
      }
      this.isLoading = true;
      try {
        const confirmStatus = await this.getPhysicianPopup(5, [specimen]); //Specimen Save
        if (!confirmStatus) {
          this.isLoading = false;
          window.notify("User cancelled action.", "error");
          return;
        }
        let payload;
        for (const editor of this.draggableEditors) {
          specimen[editor.name] = removeExtraDivs(specimen[editor.name]);
        }
        // const grossedBeforeSave = Boolean(specimen?.firstGrossedOn);
        if (specimen.id) {
          payload = this.$store.dispatch("accessionStore/updateCaseSpecimen", specimen);
        } else {
          payload = this.$store.dispatch("accessionStore/insertCaseSpecimen", specimen);
        }
        const [questionsResponse, specimensResponse] = await Promise.all([
          this.handleSaveQuestions(),
          payload
        ]);
        if (questionsResponse) {
          window.notify("Questions saved successfully.", "success");
        }
        if (specimensResponse) {
          window.notify("Specimen saved successfully.", "success");
        }

        this.$emit("save", payload);
        //! DO we have slices in cytology?
        // if (this.PrintSlidesOnSpecimenSave && !grossedBeforeSave && payload[0]?.firstGrossedOn) {
        //   this.printSlides(payload);
        // }
      } catch (error) {
        window.notify("Error saving specimen", "error");
      }
      this.isLoading = false;
    },
    displayUserName(user) {
      if (user.firstName && user.lastName) {
        return `${user.lastName}, ${user.firstName}`;
      }
      return user.username;
    },
    handleFocus() {
      this.$nextTick(() => {
        const specimenFocus = parseInt(this.specimenFocus) || null;
        let refToFocus = null;
        let shouldFocus = false;
        switch (specimenFocus) {
          case SpecimenFocusEnum.Protocol:
          case null:
            refToFocus = "protocol";
            shouldFocus = Boolean(!this.specimen.protocolId);
            break;
          case SpecimenFocusEnum.Site:
            refToFocus = "site";
            shouldFocus = true;
            break;
          case SpecimenFocusEnum.Measurement:
            refToFocus = "measurement";
            shouldFocus = true;
            break;
        }
        if (this.$refs[refToFocus]?.focus && shouldFocus) {
          this.$refs[refToFocus].focus();
        } else if (this.availableEditors?.length > 0) {
          this.focusFirstEditor();
        }
      });
    },

    focusFirstEditor() {
      const firstEditorName = this.availableEditors[0].name;
      const firstEditorRef = this.$refs[firstEditorName][0] || this.$refs[firstEditorName];
      if (firstEditorRef) {
        firstEditorRef.focus();
      }
    },
    async handleChangeBlockCount(newBlockCount) {
      try {
        const payload = await this.$store.dispatch("accessionStore/updateCaseSpecimen", {
          ...this.specimen,
          numberOfBlocks: newBlockCount
        });
        this.$emit("save", payload);
      } catch (error) {
        handleErrors(error);
      }
    },
    updateCassettes() {
      const nv = this.specimen.numberOfBlocks;
      const ov = this.specimen.cassettes.length;
      const diff = nv - ov;
      if (diff > 0) {
        const defaultPieces = this.DefaultQtyPiecesPerCassette || 1;
        for (let i = 0; i < diff; i++) {
          this.specimen.cassettes.push({ blockNum: ov + i + 1, qtyPieces: defaultPieces });
        }
      }
      if (diff < 0) {
        this.specimen.cassettes = this.specimen.cassettes.slice(0, nv);
      }
    },
    async printSlides(data) {
      const specimen = data[0];
      const slideList = await ReportsApi.slideListStore.load({
        filter: [["specimenId", "=", specimen.id], "and", ["slidePrinted", "=", false]]
      });
      if (slideList.length) {
        const slidePrinter = this.defaultGlassSlidePrinter;
        if (!slidePrinter) {
          window.notify("Slides failed to print due to no default slide printer found.", "error");
          return;
        }
        if (this.labSettings.SlidePrintingUsesBatching) {
          await ReportsApi.printGlassSlidesBatch({
            printerId: slidePrinter,
            labId: this.currentLab,
            slideIds: sortBy(slideList, ["specimenOrder", "blockNum", "slideLabelNumber"]).map(
              e => e.slideId
            )
          });
        } else {
          for (const slide of slideList) {
            await ReportsApi.printGlassSlideLabels({
              slideId: slide.slideId,
              caseId: this.caseDetails.caseId,
              specimenId: slide.specimenId,
              numberOfCopies: 1,
              printerId: slidePrinter
            }).catch(() => {
              window.notify("Error printing slide labels.", "warning");
            });
          }
        }
        window.notify(`Printed ${slideList.length} slide(s).`);
      }
    },
    async printBlocks() {
      if (this.isLoading) {
        return;
      }
      const cassettePrinter = this.defaultCassettePrinter ?? this.LabSettingDefaultCassettePrinter;
      const fileDropSettings = JSON.parse(this.FileDropPrintingConfiguration);
      if (!cassettePrinter && !fileDropSettings) {
        window.notify("Could not print blocks due to no cassette printer found.", "error");
        return;
      }
      try {
        this.isLoading = true;
        let cassettesWithoutIds = false;
        for (const cassette of this.specimen.cassettes) {
          if (!cassette?.id) {
            cassettesWithoutIds = true;
            break;
          }
        }
        if (cassettesWithoutIds) {
          await this.$store.dispatch("accessionStore/increaseSpecimenCassetteQty", this.specimen);
          this.specimen.cassettes = this.currentSpecimen.cassettes;
        }
        const protocolBinMaps = new Set();
        const protocols = await DropdownApi.getProtocol(this.caseDetails.labPrefix);
        if (fileDropSettings) {
          const defaultFileDropTray = fileDropSettings?.defaultTray;
          const labBinMaps = await PrintersApi.getLabBinMaps();
          const allCassettes = await ReportsApi.cassetteListStore.load({
            filter: [
              ["caseId", this.caseDetails.caseId],
              "and",
              ["specimenId", "=", this.specimen.id],
              "and",
              ["cassettePrinted", "=", false]
            ],
            sort: [
              { selector: "specimenOrder", desc: false },
              { selector: "blockNum", desc: false }
            ]
          });
          let payload = [];
          for (const cassette of allCassettes) {
            let trayName = defaultFileDropTray;
            if (cassette.protocolId) {
              const { protocolId } = cassette;
              const targetProtocol = protocols.find(e => e.id === protocolId);
              let specimenBinMap = {};
              if (targetProtocol?.binMapId) {
                specimenBinMap = labBinMaps.find(e => (e.id = targetProtocol.binMapId));
                const binMapObject = specimenBinMap.printers.find(
                  e => e.printerId === parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID)
                );
                if (binMapObject.bin) {
                  trayName = binMapObject.bin;
                }
              }
            }
            payload.push({
              ...cassette,
              cassetteTrayName: trayName,
              patientFirstName: this.caseDetails.patientFirstName,
              patientLastName: this.caseDetails.patientLastName
            });
          }
          await printFileDrop(payload);
          window.notify(`Printed ${payload.length} cassette label(s).`);
          const cassettesToMark = payload.map(e => e.cassetteId);
          await CassettesApi.markCassettesAsPrinted(cassettesToMark);
        } else if (cassettePrinter) {
          if (this.specimen.protocolId) {
            const { protocolId } = this.specimen;
            const targetProtocol = protocols.find(e => e.id === protocolId);
            if (targetProtocol?.binMapId) {
              protocolBinMaps.add(targetProtocol.binMapId);
            }
          }
          const labBinMapOrder = this.LabBinPrintOrder;
          const isProtocolTarget =
            typeof labBinMapOrder === "string" &&
            labBinMapOrder?.indexOf &&
            labBinMapOrder?.indexOf("protocol") === 0;
          // *** (1) If the labBinOrder has the protocol first.
          // *** (2) And if we found atleast 1 bin map on the specimen list.
          // *** (3) Then we will have to dispatch the prints one by one.

          const usesBatch = this.labSettings.CassettePrintingUsesBatching;
          if (protocolBinMaps.size > 1 || (isProtocolTarget && protocolBinMaps.size > 0)) {
            // *** ticket/IP-285
            // *** Request the latest cassettes by caseId from the database to print them 1 by 1.

            const allCassettes = await ReportsApi.cassetteListStore.load({
              filter: [
                ["caseId", this.caseDetails.caseId],
                "and",
                ["specimenId", "=", this.specimen.id],
                "and",
                ["cassettePrinted", "=", false]
              ],
              sort: [
                { selector: "specimenOrder", desc: false },
                { selector: "blockNum", desc: false }
              ]
            });
            if (usesBatch) {
              await ReportsApi.printBlockLabelsBatch({
                printerId: cassettePrinter,
                labId: this.currentLab,
                cassetteIds: allCassettes.map(e => e.cassetteId)
              });
            } else {
              for (const cassette of allCassettes) {
                // ! Protocol BinMaps are dependent on sending the specimen Id!
                await ReportsApi.printBlockLabels({
                  printerId: cassettePrinter,
                  labId: this.currentLab,
                  numberOfCopies: 1,
                  specimenId: cassette.specimenId,
                  cassetteId: cassette.cassetteId,
                  caseId: this.caseDetails.caseId
                });
              }
            }
            window.notify(`Printed ${allCassettes.length} cassette label(s).`);
          } else {
            // *** In this scenario, we can print all the cassettes at once but protocol binmaps will be ignored..
            if (usesBatch) {
              const allCassettes = await ReportsApi.cassetteListStore.load({
                filter: [
                  ["caseId", this.caseDetails.caseId],
                  "and",
                  ["specimenId", "=", this.specimen.id],
                  "and",
                  ["cassettePrinted", "=", false]
                ],
                sort: [
                  { selector: "specimenOrder", desc: false },
                  { selector: "blockNum", desc: false }
                ]
              });
              await ReportsApi.printBlockLabelsBatch({
                printerId: cassettePrinter,
                labId: this.currentLab,
                cassetteIds: allCassettes.map(e => e.cassetteId)
              });
            } else {
              await ReportsApi.printBlockLabels({
                printerId: cassettePrinter,
                labId: this.currentLab,
                numberOfCopies: 1,
                caseId: this.caseDetails.caseId
              });
            }
            window.notify(`Printed all cassette label(s).`);
          }
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleSubmitFrozenData(data) {
      window.notify("Specimen updated");
      this.specimen = { ...this.specimen, ...data };
      this.toggleFrozenForm();
    },
    handleAutoOpenEditor(editorName) {
      if (this.autoOpenEditors && this.$refs[editorName][0]?.expand) {
        this.$refs[editorName][0].expand();
      }
    },
    autoPrintOrders() {
      if (this.shouldPrintOrders) {
        this.$store.commit("accessionStore/setShouldPrintOrders", false);
        this.$store.dispatch("accessionStore/autoPrintOrders", this.staticCaseId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .text-uppercase {
  input {
    text-transform: uppercase;
  }
}

::v-deep .text-input {
  min-height: unset;
}
/** Hide accordion in cytology */
::v-deep #clinical {
  #headingOne {
    display: none !important;
  }
  .editor {
    border: none !important;
  }
  .collapse:not(.show) {
    display: block;
  }
}
:v-deep.collapse {
  display: block !important;
}

::v-deep.unlocked {
  &,
  *,
  * > *,
  * {
    cursor: move !important;
  }
}
</style>
